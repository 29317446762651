import api from './axiosInstance2';

const API_URL = '/cycle';

export const getCycles = () => api.get(API_URL);

export const getCycle = (id) => api.get(`${API_URL}/${id}`);

export const createCycle = (data) => api.post(API_URL, data);

export const updateCycle = (id, data) => api.put(`${API_URL}/${id}`, data);

export const deleteCycle = (id) => api.delete(`${API_URL}/${id}`);
