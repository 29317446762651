import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';

const Qr = () => {
    const [popupState, setPopupState] = useState(false);
    const [activeTab, setActiveTab] = useState('whatsapp');
    const [customText, setCustomText] = useState('');
    const [countryCode, setCountryCode] = useState('91');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [whatsappMessage, setWhatsappMessage] = useState('');
    const [qrCodeValue, setQrCodeValue] = useState('');
    const [qrCodeVisible, setQrCodeVisible] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
    const [foregroundColor, setForegroundColor] = useState('#000000');

    const switchTab = (tab) => {
        setActiveTab(tab);
        setQrCodeVisible(false); // Hide QR Code on tab switch
    };

    const handleCustomSubmit = (e) => {
        e.preventDefault();
        if (customText.trim()) {
            setQrCodeValue(customText);
            setQrCodeVisible(true);
        }
    };

    const handleWhatsappSubmit = (e) => {
        e.preventDefault();
        if (phoneNumber.trim() && whatsappMessage.trim()) {
            const whatsappLink = `https://wa.me/${countryCode}${phoneNumber}?text=${encodeURIComponent(
                whatsappMessage
            )}`;
            setQrCodeValue(whatsappLink);
            setQrCodeVisible(true);
        }
    };

    const downloadQRCode = () => {
        const svg = document.getElementById('qrCode');
        const svgData = new XMLSerializer().serializeToString(svg);

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const img = new Image();
        img.src = 'data:image/svg+xml;base64,' + window.btoa(svgData);
        img.onload = () => {
            const padding = 20; // Adjust the padding size as needed

            canvas.width = img.width + padding * 2; // Add padding to width
            canvas.height = img.height + padding * 2; // Add padding to height

            // Set the background color
            ctx.fillStyle = backgroundColor || '#ffffff'; // Change this to your desired background color
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Draw the QR Code image with padding
            ctx.drawImage(img, padding, padding);

            // Convert canvas to PNG and trigger download
            const pngFile = canvas.toDataURL('image/png');
            const a = document.createElement('a');
            a.href = pngFile;
            a.download = 'qr-code.png';
            a.click();
        };
    };

    return (
        <>
            {(popupState) ? (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-black bg-opacity-50 absolute inset-0">
                        <div class="relative mx-auto p-4 w-full max-w-md max-h-full">
                            <div class="relative bg-white rounded-xl shadow dark:bg-gray-700">
                                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                    <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                        Help
                                    </h3>
                                    <button onClick={() => { setPopupState(false) }} type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span class="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <div class="p-4 md:p-5">
                                    <section class="bg-white dark:bg-gray-900">
                                        <div class="container mx-auto">
                                            <div>
                                                <p class="mt-3 font-semibold text-gray-800 dark:text-gray-400">Facing any problem? Feel fee to contact.</p>
                                            </div>

                                            <div class="grid grid-cols-1 mt-6 gap-4 sm:grid-cols-2 lg:grid-cols-1">
                                                <div>
                                                    <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                                        </svg>
                                                    </span>

                                                    <h2 class="mt-4 text-base font-medium text-gray-800 dark:text-white">Email</h2>
                                                    <p class="mt-2 text-sm text-blue-500 dark:text-blue-400">adityadubey.64485@gmail.com</p>
                                                </div>

                                                <div>
                                                    <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                                        </svg>
                                                    </span>

                                                    <h2 class="mt-4 text-base font-medium text-gray-800 dark:text-white">Office</h2>
                                                    <p class="mt-2 text-sm text-blue-500 dark:text-blue-400">Block 30 - 407, LPU</p>
                                                </div>

                                                <div>
                                                    <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                                        </svg>
                                                    </span>

                                                    <h2 class="mt-  text-base font-medium text-gray-800 dark:text-white">Phone</h2>
                                                    <p class="mt-2 text-sm text-blue-500 dark:text-blue-400">+91 9015090561</p>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : ('')}



            <nav class="fixed top-0 w-full shadow-md z-10 bg-white dark:bg-gray-950 dark:border-gray-700 "
                style={{
                    backgroundImage: "url('/images/bg_img.svg')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }} >
                <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4">
                    <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src="/images/lpu.svg" class="h-20" alt="" />
                    </a>

                    <div class="hidden w-full md:block md:w-auto" id="navbar-solid-bg">
                        <span
                            class="self-center text-2xl font-semibold whitespace-nowrap text-gray-700 dark:text-white">
                            QR Code Generator
                        </span>
                    </div>
                    <span class="flex items-center space-x-3 rtl:space-x-reverse">
                        <button onClick={() => { setPopupState(true) }} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                            Help
                        </button>
                    </span>
                </div>
            </nav>
            <main>
                <div class="relative pt-20 pb-12" id="Apps">
                    <div class="mx-auto max-w-6xl px-6 md:px-12 lg:px-6 xl:px-0">
                        <div class="mt-12">
                            <div className='flex mb-5'>
                                <Link to={'/'}>
                                    <button type="button" class="flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" className='me-2' fill="currentColor"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" /></svg>
                                        Go Back
                                    </button>
                                </Link>
                            </div>
                            <div className="container mx-auto">
                                <div className="p-8 w-full bg-white border border-gray-200 rounded-2xl dark:bg-gray-800 dark:border-gray-700">


                                    {/* Tabs */}
                                    <div className="flex justify-center mb-8">
                                        <button
                                            onClick={() => switchTab('whatsapp')}
                                            className={`px-4 py-2 ${activeTab === 'whatsapp'
                                                ? 'border-b-4 border-indigo-500 text-indigo-600'
                                                : 'text-gray-600 hover:text-indigo-600'
                                                } focus:outline-none`}
                                        >
                                            WhatsApp QR Code
                                        </button>
                                        <button
                                            onClick={() => switchTab('custom')}
                                            className={`px-4 py-2 ${activeTab === 'custom'
                                                ? 'border-b-4 border-indigo-500 text-indigo-600'
                                                : 'text-gray-600 hover:text-indigo-600'
                                                } focus:outline-none`}
                                        >
                                            Custom QR Code
                                        </button>
                                    </div>
                                    <div class="flex flex-col gap-12 md:px-12 md:flex-row ">
                                        <div className='relative md:w-7/12 md:pl-12'>
                                            {/* Custom QR Code Form */}
                                            {activeTab === 'custom' && (
                                                <form className="space-y-6" onSubmit={handleCustomSubmit}>
                                                    <div>
                                                        <label className="block text-gray-700 font-medium mb-1">
                                                            Enter your text or URL:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={customText}
                                                            onChange={(e) => setCustomText(e.target.value)}
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Enter text or URL"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="grid grid-cols-6 gap-6">
                                                        <div className="col-span-3 sm:col-span-2">
                                                            <label htmlFor="backgroundColor" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Background Color</label>
                                                            <input onChange={(e) => setBackgroundColor(e.target.value)} value={backgroundColor} type="color" id="backgroundColor" className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" required />
                                                        </div>
                                                        <div className="col-span-3 sm:col-span-2">
                                                            <label htmlFor="foregroundColor" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Foreground Color</label>
                                                            <input onChange={(e) => setForegroundColor(e.target.value)} value={foregroundColor} type="color" id="foregroundColor" className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" required />
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        className="w-full bg-indigo-600 text-white font-semibold py-2 rounded-lg hover:bg-indigo-500"
                                                    >
                                                        Generate QR Code
                                                    </button>
                                                </form>
                                            )}

                                            {/* WhatsApp QR Code Form */}
                                            {activeTab === 'whatsapp' && (
                                                <form className="space-y-6" onSubmit={handleWhatsappSubmit}>
                                                    <div>
                                                        <label className="block text-gray-700 font-medium mb-1">
                                                            Select Country Code:
                                                        </label>
                                                        <select
                                                            value={countryCode}
                                                            onChange={(e) => setCountryCode(e.target.value)}
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            required
                                                        >
                                                            <option value="91">India (+91)</option>
                                                            <option value="1">USA (+1)</option>
                                                            {/* Add more country codes here */}
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label className="block text-gray-700 font-medium mb-1">
                                                            Enter Phone Number:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={phoneNumber}
                                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Phone Number"
                                                            required
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className="block text-gray-700 font-medium mb-1">
                                                            Enter Default Message:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={whatsappMessage}
                                                            onChange={(e) => setWhatsappMessage(e.target.value)}
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Hello, I would like to..."
                                                            required
                                                        />
                                                    </div>
                                                    <div className="grid grid-cols-6 gap-6">
                                                        <div className="col-span-3 sm:col-span-2">
                                                            <label htmlFor="backgroundColor" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Background Color</label>
                                                            <input onChange={(e) => setBackgroundColor(e.target.value)} value={backgroundColor} type="color" id="backgroundColor" className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" required />
                                                        </div>
                                                        <div className="col-span-3 sm:col-span-2">
                                                            <label htmlFor="foregroundColor" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Foreground Color</label>
                                                            <input onChange={(e) => setForegroundColor(e.target.value)} value={foregroundColor} type="color" id="foregroundColor" className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" required />
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        className="w-full bg-indigo-600 text-white font-semibold py-2 rounded-lg hover:bg-indigo-500"
                                                    >
                                                        Generate WhatsApp QR Code
                                                    </button>
                                                </form>
                                            )}

                                            {qrCodeVisible && (
                                                <div className="text-center">

                                                    <button
                                                        onClick={downloadQRCode}
                                                        className="w-full bg-green-600 text-white font-semibold py-2 mt-4 rounded-lg hover:bg-green-500"
                                                    >
                                                        Download QR Code
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        <div className='md:w-5/12'>
                                            {/* QR Code Display */}
                                            {qrCodeVisible ? (
                                                <div className='md:me-12 pt-5 h-[290px] w-[290px]' style={{
                                                    backgroundColor: backgroundColor || "#ffffff",
                                                }}>
                                                    <QRCode
                                                        id='qrCode'
                                                        className='mx-auto my-auto'
                                                        bgColor={backgroundColor}
                                                        fgColor={foregroundColor}
                                                        value={qrCodeValue}
                                                        size={250}
                                                    />

                                                </div>
                                            ) : (
                                                <div className='w-[250px] h-[250px] bg-slate-200 mx-auto md:float-end md:me-12'>

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >




            <footer class="bg-white border-t-2 border-gray-100 dark:bg-gray-900"
                style={{
                    backgroundImage: "url('/images/bg_img.svg')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }} >
                <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <a href="#" class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                            <img src="/images/LPU-logo.svg" class="h-28" alt="LPU Logo" />

                        </a>
                        <ul
                            class="flex flex-wrap items-center mb-6 text-md font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                            <li>
                                <a href="#" class="hover:underline me-4 md:me-6">About</a>
                            </li>
                            <li>
                                <a href="#" class="hover:underline me-4 md:me-6">Server Status</a>
                            </li>
                            <li>
                                <span onClick={() => { setPopupState(true) }} class="hover:underline cursor-pointer">Contact</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Qr;
