import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './components/main/PrivateRoute';
import { ApiProvider } from './contexts/ApiContext';
import { HelmetProvider } from 'react-helmet-async';

import Logout from './components/auth/Logout';
import Login from './components/auth/Login';
import LoginAnalytics from './components/auth/LoginAnalytics';
import MainApp from './components/main/MainApp';

//
import ChangePassword from './components/profile/ChangePassword';
import CountdownDashboard from './components/Countdown/Dashboard';
import EditTimer from './components/Countdown/UpdateCoundown';
import CreateTimer from './components/Countdown/CreateCoundown';
import Error from './components/pages/Error';
import Index from './components/home';
import Analytics from './components/home/analytics';
import Qr from './components/home/qr';
import AnalyticsDashboard from './components/Analytics/Dashboard';
import UploadReport from './components/Analytics/UploadReport';
import ViewReport from './components/Analytics/ViewReport';
import CyclePage from './components/cycle/CyclePage';
import StageBased from './components/cycle/StageBased';
import Strategy from './components/Strategy/StrategyDashboard';
import LoginStrategy from './components/auth/LoginStrategy';


const App = () => {
  const [status, setStatus] = useState(null);
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        <ApiProvider>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/qr" element={<Qr />} />
            <Route path="/cycle" element={<PrivateRoute><CyclePage /></PrivateRoute>} />
            <Route path="/countdown" element={<Login />} />
            <Route path="/strategy" element={<LoginStrategy />} />
            <Route path="/analytics/reports" element={<LoginAnalytics />} />
            <Route path="/logout" element={<Logout />} />
            <Route path='/error' element={<Error />}></Route>
            <Route path='/dashboard/strategy/stagebased' element={<PrivateRoute><StageBased /></PrivateRoute>}></Route>
            <Route path="/dashboard" element={<PrivateRoute><MainApp /></PrivateRoute>} >
              <Route path='/dashboard' element={<PrivateRoute><CountdownDashboard /></PrivateRoute>}></Route>
              <Route path='/dashboard/strategy' element={<PrivateRoute><Strategy /></PrivateRoute>}></Route>
              <Route path='/dashboard/reports' element={<PrivateRoute><AnalyticsDashboard /></PrivateRoute>}></Route>
              <Route path='/dashboard/reports/uploadreport' element={<PrivateRoute><UploadReport /></PrivateRoute>}></Route>
              <Route path='/dashboard/reports/report/:id' element={<PrivateRoute><ViewReport /></PrivateRoute>}></Route>
              
              <Route path='/dashboard/createtimer' element={<PrivateRoute><CreateTimer /></PrivateRoute>}></Route>
              <Route path='/dashboard/edittimer/:id' element={<PrivateRoute><EditTimer /></PrivateRoute>}></Route>
              <Route path='/dashboard/profile/changepassword' element={<PrivateRoute><ChangePassword /></PrivateRoute>}></Route>

            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ApiProvider>
      </Router>
    </HelmetProvider>
  );
};

export default App;
