// frontend/src/CyclePage.js
import React, { useEffect, useState } from 'react';
import CycleList from './CycleList';
import CycleDetails from './CycleDetails';
import CycleCreator from './CycleCreator';
import { createCycle, updateCycle } from '../../api/cycleService';

const CyclePage = () => {
  const [selectedCycle, setSelectedCycle] = useState(null);
  const [update, setUpdate] = useState(false);

  const handleCreate = async (newCycle) => {
    await createCycle(newCycle);
    setUpdate(!update);
    setSelectedCycle(null);
  };

  const handleUpdate = async (updatedCycle) => {
    await updateCycle(updatedCycle._id, updatedCycle);
    setUpdate(!update);
    setSelectedCycle(null);
  };

  return (
    <div>
      <CycleCreator onCreate={handleCreate} />
      <CycleList update={update} onSelect={(cycle) => setSelectedCycle(cycle)} />
      {selectedCycle && (
        <CycleDetails cycle={selectedCycle} onUpdate={handleUpdate} />
      )}
    </div>
  );
};

export default CyclePage;
