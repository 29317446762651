import React, { useState, useEffect } from 'react';
import api from '../../api/axiosInstance2';
import { Link, useOutletContext, useParams } from 'react-router-dom';

const ViewReport = () => {
    const { id } = useParams();
    const [setBreadcrumb, setBreadcrumbLink] = useOutletContext();
    const [reports, setCountdowns] = useState([]);
    const [report, setReport] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [reportsNotNull, setCountdownsNotNull] = useState(true);
    const [deletePopup, setDeletePopup] = useState(false);
    const [previewPopup, setPreviewPopup] = useState(false);
    const [popupId, setPopupId] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        setBreadcrumb(['Communication Reports', 'View Report']);
        setBreadcrumbLink(['/dashboard/reports', '#']);
    }, [setBreadcrumb, setBreadcrumbLink]);

    const fetchPosts = async () => {
        try {
            const response = await api.get(`/reports/${id}/records`);
            if (!response.data.length) {
                setCountdownsNotNull(false)
            }
            setCountdowns(response.data);
        } catch (error) {
            console.error('Error fetching reports:', error);
            if (error.message === 'Network Error') {
                setError('No Internet Connection');
            } else { console.error(`Error: ${error.message}`); }
        }
    };

    const fetchReport = async () => {
        try {
            const response = await api.get(`/reports/${id}`);
            console.log(response.data);
            
            setReport(response.data);
        } catch (error) {
            console.error('Error fetching reports:', error);
            if (error.message === 'Network Error') {
                setError('No Internet Connection');
            } else { console.error(`Error: ${error.message}`); }
        }
    };
    useEffect(() => {
        fetchReport();
        fetchPosts();
    }, []);

    const deleteAllRecords = async (id) => {
        try {
            let res = await api.delete(`/reports/${id}/records`);
            if (!res.data.error) {
                console.log('Countdown deleted updated.');
                fetchPosts();
            } else {
                setError(res.data.message);
            }
        } catch (error) {
            if (error.message === 'Network Error') {
                setError("No Internet Connection.")
            } else {
                console.error('Error adding post:', error);
            }
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredUsers = !searchTerm
        ? reports
        : reports.filter((user) => {
            const userString = Object.values(user).join(" ").toLowerCase();
            return userString.includes(searchTerm);
        });


    if (reports.length < 1 && reportsNotNull) return (
        <>
            <div class="flex items-center justify-center w-full h-[90vh]">
                <div role="status">
                    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </>
    )
    return (
        <>

            <div id="delete-modal" tabindex="-1" class={`${(deletePopup) ? ('') : ('hidden')} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 bg-[#878b9385] justify-center flex items-center w-full md:inset-0 h-full max-h-full`}>
                <div class="relative p-4 w-full max-w-md max-h-full">
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

                        <div class="p-4 md:p-5 text-center">
                            <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Delete report with ID {id}</h3>
                            <button onClick={() => { deleteAllRecords(id); setDeletePopup(false) }} data-modal-hide="delete-modal" type="button" class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                Delete
                            </button>
                            <button onClick={() => setDeletePopup(false)} type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-4 bg-white sm:p-6 dark:bg-gray-800">
                <div class="items-center justify-between lg:flex">
                    <div class="mb-4 lg:mb-0">
                        <h3 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">Report  #{id}</h3>

                    </div>
                    <div className='ml-auto mb-3'>
                        <button type="button" onClick={() => setDeletePopup(true)} class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete All Records</button>
                    </div>
                    <span class="text-base mb-3 font-normal text-gray-500 dark:text-gray-400">
                        <input onChange={handleSearchChange} type="text" name="search" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-full focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="Search..." />
                    </span>
                </div>
                <div class="flex flex-col mt-6">
                    <div class="overflow-x-auto shadow rounded-lg">
                        <div class="inline-block min-w-full align-middle">
                            <div class="overflow-hidden sm:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                                    <thead class="bg-gray-50 dark:bg-gray-700">
                                        <tr>
                                            <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                                                Report ID
                                            </th>
                                            <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                                                Report Type
                                            </th>
                                            <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                                                Records Uploaded
                                            </th>
                                            <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                                                Uploaded By
                                            </th>
                                            <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                                                Date
                                            </th>
                                            <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                                                Upload File
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white dark:bg-gray-800">
                                            <tr>
                                                <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                                                    {report._id}
                                                </td>
                                                <td class="p-4 whitespace-nowrap capitalize">
                                                    {report.reportType}
                                                </td>
                                                <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                                                    {report.recordCount}
                                                </td>
                                                <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                                                    {report.uploadedBy}
                                                </td>
                                                <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                                                    {report.uploadDate}
                                                </td>
                                                <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                                                    {report.fileName}
                                                </td>
                                            </tr>
                                    </tbody>
                                </table>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col mt-6">
                    <div class="overflow-x-auto shadow rounded-lg">
                        <div class="inline-block min-w-full align-middle">
                            <div class="overflow-hidden sm:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                                    <thead class="bg-gray-50 dark:bg-gray-700">
                                        <tr>
                                            <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                                                Record ID
                                            </th>
                                            <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                                                Job Id
                                            </th>
                                            <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                                                Automation Id
                                            </th>
                                            <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                                                Target Audience
                                            </th>
                                            <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                                                Date
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white dark:bg-gray-800">
                                        {filteredUsers.map((report) => (
                                            <tr key={report._id}>
                                                <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                                                    {report._id}
                                                </td>
                                                <td class="p-4 whitespace-nowrap">
                                                    {report.jobId}
                                                </td>
                                                <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                                                    {report.automationId}
                                                </td>
                                                <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                                                    {report.targeted}
                                                </td>
                                                <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                                                    {report.start}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {(!reportsNotNull) ? (<div className='capitalize w-100 text-center text-orange-800 text-sm font-medium mr-2 px-5 py-2 dark:text-orange-400'>No record<small>(s)</small> found</div>) : (<></>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewReport;
