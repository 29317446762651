import { Link } from 'react-router-dom';
import Logo from '../../logo.webp';
import React from 'react';

const Error = () => {

    return (
        <div data-modal-target=''>
            <div className="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
                <a href="/" className="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10 dark:text-white">
                    <img src={Logo} className="mr-4 h-11" alt="LPU" />
                </a>
                <section class="bg-white dark:bg-gray-900">
                    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                        <div class="mx-auto max-w-screen-sm text-center">
                            <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">500</h1>
                            <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Internal Server Error.</p>
                            <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">There seems to be an unexpected problem. </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Error;
