import React, { useEffect, useState } from 'react';

const StageBasedCycleDetails = ({ cycle, setPopupState }) => {
  const [currentCycle, setCurrentCycle] = useState(cycle);
  const [selectedAutomationType, setSelectedAutomationType] = useState('email');
  useEffect(() => {
    setCurrentCycle(cycle)
  }, [cycle])

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-black bg-opacity-50 absolute inset-0">
        <div class="relative mx-auto p-4 w-full ">
          <div class="relative bg-white rounded-xl shadow dark:bg-gray-700">
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <div class="m-0">
                <h3 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">{currentCycle.cycleName}</h3>
              </div>
              <div className='ml-auto me-2 text-md text-gray-900 dark:text-white'>
                Select Automation
              </div>
              <div class=" text-base font-normal text-gray-500 dark:text-gray-400">
                <select class="py-2 px-5 me-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  value={selectedAutomationType}
                  onChange={(e) => setSelectedAutomationType(e.target.value)}
                >
                  <option value="email">Email</option>
                  <option value="whatsapp">WhatsApp</option>
                  <option value="sms">SMS</option>
                </select>
              </div>
              <button onClick={() => { setPopupState(false) }} type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <div class="p-4 md:p-5 h-[40vw] overflow-x-scroll">
              <section class="bg-white dark:bg-gray-900">
                <div class="container mx-auto">
                  <div class="p-4 bg-white sm:p-6 dark:bg-gray-800">
                    <div class="items-center justify-between lg:flex">
                      <div class="mb-4 lg:mb-0">
                        <h3 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">{selectedAutomationType.toUpperCase()} Automation</h3>
                      </div>
                    </div>
                    <div class="grid grid-flow-row-dense grid-cols-3">
                      <div class="col-span-2">
                        <div class="flex flex-col items-center">
                          <div class="bg-blue-500 text-white p-4 w-48 text-center">
                            <span>Start</span>
                          </div>
                          <div class="w-1 h-12 bg-gray-700"></div>
                          {currentCycle[selectedAutomationType].map((automation, index) => (
                            <>
                              {automation.type === 'Template'
                                ? (selectedAutomationType === 'email' ? (
                                  <div class="bg-green-500 text-white p-4">
                                    <div class="flex">
                                      <div class="border-r-2 pe-2 me-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                          fill="#FFFFFF">
                                          <path
                                            d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
                                        </svg>
                                      </div>
                                      <div class="col-span-2">
                                        <a target="_blank" href={automation.value.templateLink || '#'}>
                                          {automation.value.templateName || 'Unnamed'}
                                        </a>
                                      </div>
                                    </div>
                                  </div>) : selectedAutomationType === 'sms' ? (
                                    <div class="bg-green-500 text-white p-4">
                                      <div class="flex">
                                        <div class="border-r-2 pe-2 me-3">
                                          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                            fill="#FFFFFF">
                                            <path
                                              d="M320-520q17 0 28.5-11.5T360-560q0-17-11.5-28.5T320-600q-17 0-28.5 11.5T280-560q0 17 11.5 28.5T320-520Zm160 0q17 0 28.5-11.5T520-560q0-17-11.5-28.5T480-600q-17 0-28.5 11.5T440-560q0 17 11.5 28.5T480-520Zm160 0q17 0 28.5-11.5T680-560q0-17-11.5-28.5T640-600q-17 0-28.5 11.5T600-560q0 17 11.5 28.5T640-520ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z" />
                                          </svg>
                                        </div>
                                        <div class="col-span-2">
                                          <a target="_blank" href={automation.value.templateLink}>
                                            {automation.value.templateName || 'Unnamed'}
                                          </a>
                                        </div>
                                      </div>
                                    </div>) : (
                                  <div class="bg-green-500 text-white p-4">
                                    <div class="flex">
                                      <div class="border-r-2 pe-2 me-3">
                                        <svg fill="#FFFFFF" height="22px" width="22px" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 308 308" xmlSpace="preserve">
                                          <g id="XMLID_468_">
                                            <path id="XMLID_469_"
                                              d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156 c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687 c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887 c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153 c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348 c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802 c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922 c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0 c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458 C233.168,179.508,230.845,178.393,227.904,176.981z" />
                                            <path id="XMLID_470_"
                                              d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716 c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396 c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z  M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188 l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867 C276.546,215.678,222.799,268.994,156.734,268.994z" />
                                          </g>
                                        </svg>
                                      </div>
                                      <div class="col-span-2">
                                        <a target="_blank" href={automation.value.templateLink}>
                                          {automation.value.templateName || 'Unnamed'}
                                        </a>
                                      </div>
                                    </div>
                                  </div>))

                                : automation.type === 'Delay'
                                  ? (
                                    <div class="bg-yellow-400 text-white p-4">
                                      <div class="flex">
                                        <div class="border-r-2 pe-2 me-3">
                                          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                                            width="24px" fill="#FFFFFF">
                                            <path
                                              d="M360-840v-80h240v80H360Zm80 440h80v-240h-80v240Zm40 320q-74 0-139.5-28.5T226-186q-49-49-77.5-114.5T120-440q0-74 28.5-139.5T226-694q49-49 114.5-77.5T480-800q62 0 119 20t107 58l56-56 56 56-56 56q38 50 58 107t20 119q0 74-28.5 139.5T734-186q-49 49-114.5 77.5T480-80Zm0-80q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z" />
                                          </svg>
                                        </div>
                                        <div class="col-span-2 ...">
                                          {automation.value}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                  : (
                                    <div class="parallelogram bg-slate-500 text-white">
                                      <div class="parallelogram-content p-4 px-8">
                                        <ul class="max-w-md space-y-1 text-gray-50 list-disc list-inside dark:text-gray-400">

                                          {automation.value.map((condition, index) => (
                                            <li key={index} class="text-white">
                                              {condition}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>)}
                              <div class="w-1 h-12 bg-gray-700"></div>
                            </>
                          ))}
                          <div class="parallelogram bg-red-500 text-white p-4 w-48 text-center">
                            <div class="parallelogram-content">
                              END
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>&nbsp;</div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StageBasedCycleDetails;
