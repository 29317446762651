import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import api from '../../api/axiosInstance2';
import { getToken, setToken, removeToken } from '../../utils/secureStorage';

const UploadReport = () => {
    const navigate = useNavigate();
    const [setBreadcrumb, setBreadcrumbLink] = useOutletContext();
    const [type, setName] = useState('email');
    const [file, setFile] = useState(null);
    const [proceeding, setProceeding] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        setBreadcrumb(['Communication Reports', 'Upload Report']);
        setBreadcrumbLink(['/dashboard/reports', '#']);
    }, [setBreadcrumb, setBreadcrumbLink]);

    const ReportUpload = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('file', file);
            if (type === 'email') {
                setProceeding(true);
                let res = await api.post(`/report/email`, formData, {
                    headers: {
                        ...api.defaults.headers.common,
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (!res.data.error) {
                    console.log('Report uploaded successfully.');
                    navigate('/dashboard/reports');
                } else {
                    setProceeding(null);
                    setError(res.data.message);
                    alert(res.data.message);
                }
            } else if (type === 'sms') {
                setProceeding(true);
                let res = await api.post(`/report/sms`, formData, {
                    headers: {
                        ...api.defaults.headers.common,
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (!res.data.error) {
                    console.log('Report uploaded successfully.');
                    navigate('/dashboard/reports');
                } else {
                    setProceeding(null);
                    setError(res.data.message);
                    alert(res.data.message);
                }
            } else {
                setProceeding(true);
                let res = await api.post(`/report/whatsapp`, formData, {
                    headers: {
                        ...api.defaults.headers.common,
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (!res.data.error) {
                    console.log('Report uploaded successfully.');
                    navigate('/dashboard/reports');
                } else {
                    setProceeding(null);
                    setError(res.data.message);
                    alert(res.data.message);
                }
            }
        } catch (error) {
            setProceeding(null);
            if (error.message === 'Network Error') {
                setError("No Internet Connection.")
            } else {
                console.error('Error adding post:', error);
            }
        }
    };

    return (
        <>
            <div className="p-4 bg-white sm:p-6 dark:bg-gray-800">
                <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                    <h3 className="mb-4 text-xl font-semibold dark:text-white">Upload Report</h3>
                    <form method="post" encType="multipart/form-data" onSubmit={(e) => ReportUpload(e)}>
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type</label>
                                <select onChange={(e) => setName(e.target.value)} value={type} type="type" id="type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                                    <option value={'email'}> Email </option>
                                    <option value={'sms'}> SMS </option>
                                    <option value={'whatsapp'}> WhatsApp </option>
                                </select>
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Record (CSV File):</label>
                                <input type="file" className='block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400' name="file" onChange={(e) => {
                                    setFile(e.target.files[0])
                                }} required />
                            </div>

                            <div className="col-span-6 sm:col-full">
                                <button typeof="submit" className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800" type="submit">
                                    {proceeding ? (
                                        <>
                                            <div className="inline mr-3" role="status">
                                                <svg aria-hidden="true" className="w-5 h-5 inline text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67227 50 9.67227C27.4013 9.67227 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.723 75.2124 7.55316C69.5422 4.38329 63.2754 2.51526 56.7335 2.08404C51.7661 1.74012 46.7787 2.38589 42.0637 3.98731C39.5466 4.81519 38.0842 7.343 38.7213 9.76854C39.3585 12.1941 41.8408 13.6585 44.3491 12.8944C47.9682 11.8201 51.7359 11.5292 55.4318 11.9447C60.3374 12.5079 65.0716 14.2405 69.2762 17.0357C73.4807 19.8308 77.0427 23.6187 79.6469 28.1491C81.9113 32.0014 83.3647 36.2565 83.9235 40.6341C84.336 43.3453 86.7423 45.0712 89.1677 44.4341Z" fill="currentFill" />
                                                </svg>
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            Uploading...
                                        </>
                                    ) : 'Upload Report'}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default UploadReport;
