import React, { useState, useEffect } from 'react';
import api from '../../api/axiosInstance';
import { Link, useOutletContext } from 'react-router-dom';

const Strategy = () => {
    const [setBreadcrumb, setBreadcrumbLink] = useOutletContext();
    useEffect(() => {
        setBreadcrumb(['Communication Strategy']);
        setBreadcrumbLink(['/dashboard/strategy/']);
    }, [setBreadcrumb, setBreadcrumbLink]);

    return (
        <>
            <div class="p-4 bg-white sm:p-6 dark:bg-gray-800">
                <div class="items-center justify-between lg:flex">
                    <div class="mb-4 lg:mb-0">
                        <h3 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">Communication Strategy</h3>

                    </div>
                    <div className='ml-auto mb-3'>

                    </div>
                </div>
                <div class="relative mt-3 mb-12" id="Apps">
                    <div class="mx-auto max-w-6xl px-6 md:px-12 lg:px-6 xl:px-0">
                        <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
                            <Link to={'/dashboard/strategy/stagebased'}>
                                <div class="w-full max-w-sm bg-white border border-gray-200 rounded-2xl transition duration-500  hover:shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div class="flex justify-end px-4 pt-4">
                                        <div class=" flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-1.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                                            <div className='inline w-3 h-3 bg-yellow-400 rounded-full mt-1 me-1'></div>
                                            <span>Refining</span>
                                        </div>
                                    </div>
                                    <div class="flex flex-col items-center pb-10">
                                        <div className='bg-slate-200 w-24 h-24 mb-3 rounded-full shadow-sm p-6'>
                                            <svg class="w-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="#EF7D00"><path d="M220-520 80-600v-160l140-80 140 80v160l-140 80Zm0-92 60-34v-68l-60-34-60 34v68l60 34Zm440 123v-93l140 82v280L560-80 320-220v-280l140-82v93l-60 35v188l160 93 160-93v-188l-60-35Zm-140 89v-480h360l-80 120 80 120H600v240h-80Zm40 69ZM220-680Z"/></svg>
                                        </div>
                                        <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Stage Based</h5>
                                        <span class="text-sm text-gray-500 dark:text-gray-400 p-2">Communication Strategy for 2025</span>

                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Strategy;
