// frontend/src/components/CycleCreator.js
import React, { useState } from 'react';

const CycleCreator = ({ onCreate }) => {
  const [cycleName, setCycleName] = useState('');

  const handleCreate = () => {
    const newCycle = { cycleName, email: [], whatsapp: [], sms: [] };
    onCreate(newCycle);
  };

  return (
    <div class="p-4 bg-white sm:p-6 dark:bg-gray-800">
      <div class="items-center justify-between lg:flex">
        <div class="mb-4 lg:mb-0">
          <h3 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">Create Cycle</h3>
        </div>
        <div class="ml-auto me-2 text-base mb-3 font-normal text-gray-500 dark:text-gray-400">
          <input type="text"
            value={cycleName}
            onChange={(e) => setCycleName(e.target.value)}
            placeholder="Cycle Name" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-full focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" />
        </div>
        <div className='mb-3'>
          <button onClick={handleCreate} type="button" class="py-2 px-5 me-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Create Cycle</button>
        </div>
      </div>
    </div>
  );
};

export default CycleCreator;
