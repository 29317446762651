// frontend/src/components/CycleList.js
import React, { useEffect, useState } from 'react';
import { getCycles, deleteCycle } from '../../api/cycleService';

const CycleList = ({ update, onSelect }) => {
  const [cycles, setCycles] = useState([]);

  useEffect(() => {
    loadCycles();
  }, [update]);

  const loadCycles = async () => {
    const { data } = await getCycles();
    setCycles(data);
  };

  const handleDelete = async (id) => {
    await deleteCycle(id);
    loadCycles();
  };

  return (
    <div>
      <div class="p-4 bg-white sm:p-6 dark:bg-gray-800">
        <div class="mb-4 lg:mb-0">
          <h3 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">Cycle List</h3>
        </div>
        <div class="flex flex-col mt-6">
          <div class="overflow-x-auto shadow rounded-lg">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                  <thead class="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        ID
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Name
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {cycles.map((cycle) => (
                    <tr key={cycle._id}>
                      <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        {cycle._id}</td>
                      <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        {cycle.cycleName}</td>
                      <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        <button class="py-2 px-5 me-2 mb-2 text-xs text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={() => onSelect(cycle)}>View</button>
                        <button class="py-2 px-5 me-2 mb-2 text-xs text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={() => handleDelete(cycle._id)}>Delete</button></td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CycleList;
