import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeToken } from '../../utils/secureStorage';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = () => {
      removeToken('accessToken');
      removeToken('refreshToken');
      navigate('/countdown');
    };

    performLogout();
  }, [navigate]);

  return null;
};

export default Logout;
