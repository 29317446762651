import React, { useEffect, useState } from 'react';

const CycleDetails = ({ cycle, onUpdate }) => {
  const [currentCycle, setCurrentCycle] = useState(cycle);
  const [selectedAutomationType, setSelectedAutomationType] = useState('email'); // Options: email, whatsapp, sms
  const [editingAutomation, setEditingAutomation] = useState(null);
  const [newAutomation, setNewAutomation] = useState({
    type: 'Condition', // Default type
    value: {
      templateName: '', // New for Template type
      templateLink: '', // New for Template type
    },
  });

  useEffect(() => {
    setCurrentCycle(cycle)
  }, [cycle])

  // Handle changes for new or edited automation
  const handleAutomationChange = (e) => {
    const { name, value } = e.target;

    if (newAutomation.type === 'Template') {
      setNewAutomation((prev) => ({
        ...prev,
        value: {
          ...prev.value,
          [name]: value, // Dynamically update templateName or templateLink
        },
      }));
    } else {
      setNewAutomation((prev) => ({
        ...prev,
        [name]: name === 'value' ? JSON.parse(value || '{}') : value,
      }));
    }
  };

  // Add a new automation
  const handleAddAutomation = () => {
    if (!newAutomation.type || !newAutomation.value) return;

    setCurrentCycle((prev) => ({
      ...prev,
      [selectedAutomationType]: [...prev[selectedAutomationType], newAutomation],
    }));
    setNewAutomation({ type: 'Condition', value: '' });
  };

  // Edit an existing automation
  const handleEditAutomation = (index) => {
    const automationToEdit = currentCycle[selectedAutomationType][index];
    setEditingAutomation({ ...automationToEdit, index });
    setNewAutomation(automationToEdit);
  };

  const handleSaveEdit = () => {
    setCurrentCycle((prev) => ({
      ...prev,
      [selectedAutomationType]: prev[selectedAutomationType].map((automation, idx) =>
        idx === editingAutomation.index ? newAutomation : automation
      ),
    }));
    setEditingAutomation(null);
    setNewAutomation({ type: 'Condition', value: '' });
  };

  // Delete an automation
  const handleDeleteAutomation = (index) => {
    setCurrentCycle((prev) => ({
      ...prev,
      [selectedAutomationType]: prev[selectedAutomationType].filter((_, idx) => idx !== index),
    }));
  };

  // Save changes to the cycle
  const handleSave = () => {
    onUpdate(currentCycle);
  };

  return (
    <div class="p-4 bg-white sm:p-6 dark:bg-gray-800">
      <div class="items-center justify-between lg:flex">
        <div class="mb-4 lg:mb-0">
          <h3 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">Cycle: {currentCycle.cycleName}</h3>
        </div>
        <div className='ml-auto me-2 mb-3 text-md text-gray-900 dark:text-white'>
          Select Automation
        </div>
        <div class=" text-base mb-3 font-normal text-gray-500 dark:text-gray-400">
          <select class="py-2 px-5 me-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            value={selectedAutomationType}
            onChange={(e) => setSelectedAutomationType(e.target.value)}
          >
            <option value="email">Email</option>
            <option value="whatsapp">WhatsApp</option>
            <option value="sms">SMS</option>
          </select>
        </div>
      </div>
      <div class="items-center justify-between lg:flex">
        <div class="mb-4 lg:mb-0">
          <h3 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">{selectedAutomationType.toUpperCase()} Automations</h3>
        </div>
      </div>
      <div>
        <div class="flex flex-col mt-6">
          <div class="mb-3 overflow-x-auto shadow rounded-lg">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                  <thead class="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        ID
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Name
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Link
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {currentCycle[selectedAutomationType].map((automation, index) => (
                    <tr key={index}>
                      <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        {automation.type}
                      </td>
                      <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        {automation.type === 'Template'
                          ? `${automation.value.templateName || 'Unnamed'}`
                          : automation.type === 'Delay'
                            ? `${automation.value}`
                            : `${JSON.stringify(automation.value)}`}
                      </td>
                      <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        {automation.type === 'Template'
                          ? `${automation.value.templateLink || 'Pending'}`
                          : `NA`}
                      </td>
                      <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        <button class="py-2 px-5 me-2 mb-2 text-xs text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={() => handleEditAutomation(index)}>Edit</button>
                        <button class="py-2 px-5 me-2 mb-2 text-xs text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={() => handleDeleteAutomation(index)}>Delete</button>
                      </td>
                    </tr>
                  ))}

                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="items-center justify-between lg:flex">
          <div class="mb-4 lg:mb-0">
            <h3 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">{editingAutomation ? 'Edit Automation' : 'Add New Entry'}</h3>
          </div>
          <div className='ml-auto me-2 mb-3 text-md text-gray-900 dark:text-white'>
            Type
          </div>
          <div class=" text-base mb-3 font-normal text-gray-500 dark:text-gray-400">
            <select class="py-2 px-5 me-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              name="type"
              value={newAutomation.type}
              onChange={(e) =>
                setNewAutomation((prev) => ({
                  ...prev,
                  type: e.target.value,
                  value: e.target.value === 'Template'
                    ? { templateName: '', templateLink: '' }
                    : '', // Clear value for other types
                }))
              }
            >
              <option value="Condition">Condition</option>
              <option value="Delay">Delay</option>
              <option value="Template">Template</option>
            </select>

          </div>
          {editingAutomation && (<button onClick={() => { setEditingAutomation(null); }} type='button' class="py-2 px-5 me-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"> Add New Entry </button>)}

        </div>

        {newAutomation.type === 'Template' && (
          <div>
            <label>Template Name: </label>
            <input class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-md p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="text"
              name="templateName"
              placeholder="Enter template name"
              value={newAutomation.value.templateName || ''}
              onChange={(e) =>
                setNewAutomation((prev) => ({
                  ...prev,
                  value: {
                    ...prev.value,
                    templateName: e.target.value, // Update only templateName
                  },
                }))
              }
            />

            <label>Template Link: </label>
            <input class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-md p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="text"
              name="templateLink"
              placeholder="Enter template link"
              value={newAutomation.value.templateLink || ''}
              onChange={(e) =>
                setNewAutomation((prev) => ({
                  ...prev,
                  value: {
                    ...prev.value,
                    templateLink: e.target.value, // Update only templateLink
                  },
                }))
              }
            />
          </div>
        )}

        {newAutomation.type === 'Delay' && (
          <div>
            <label>Delay: </label>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-md p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="text"
              name="value"
              placeholder="Enter delay"
              value={newAutomation.value || ''} // Ensure value is not an object
              onChange={(e) =>
                setNewAutomation((prev) => ({
                  ...prev,
                  value: e.target.value, // Directly set the delay value
                }))
              }
            />
          </div>
        )}

        {newAutomation.type === 'Condition' && (
          <div>
            <label>Condition List: </label>
            <textarea class="block p-2.5 w-72 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              name="value"
              placeholder="Enter condition list (comma-separated)"
              value={Array.isArray(newAutomation.value) ? newAutomation.value.join(',') : ''}
              onChange={(e) =>
                handleAutomationChange({
                  target: {
                    name: 'value',
                    value: JSON.stringify(e.target.value.split(',')),
                  },
                })
              }
            />
          </div>
        )}

        <button className="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" onClick={editingAutomation ? handleSaveEdit : handleAddAutomation}>
          {editingAutomation ? 'Save Changes' : 'Add Entry'}
        </button>
      </div>

      <button class="flex mt-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800" onClick={handleSave}>
      <svg className='me-2' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M160-160v-80h109q-51-44-80-106t-29-134q0-112 68-197.5T400-790v84q-70 25-115 86.5T240-480q0 54 21.5 99.5T320-302v-98h80v240H160Zm440 0q-50 0-85-35t-35-85q0-48 33-82.5t81-36.5q17-36 50.5-58.5T720-480q53 0 91.5 34.5T858-360q42 0 72 29t30 70q0 42-29 71.5T860-160H600Zm116-360q-7-41-27-76t-49-62v98h-80v-240h240v80H691q43 38 70.5 89T797-520h-81ZM600-240h260q8 0 14-6t6-14q0-8-6-14t-14-6h-70v-50q0-29-20.5-49.5T720-400q-29 0-49.5 20.5T650-330v10h-50q-17 0-28.5 11.5T560-280q0 17 11.5 28.5T600-240Zm120-80Z"/></svg>
        Sync Changes</button>
    </div>
  );
};

export default CycleDetails;
